import { useEffect, useState } from "react";
import api from "../../../../../services/api";
import TableFiles from "./ListFilesTypeAndSubTypes";
import Loading from "../../../others/LoadingFull";

export default function SacopListTypeAndSubtype(props) {
  const { type, subType, nameModule } = props;
  const [removeLoading, setRemoveLoading] = useState(false);
  const [resultfile, setFile] = useState([]);
  const [cnpj, setCNPJ] = useState("");

  useEffect(() => {
    if (type != undefined && subType != undefined) {
      const data = { type, subType };
      api
        .post(`/atosAdministrativos/list-by-type-and-subtype`, data)
        .then((res) => {
          if (!res.data.err) {
            const result = res.data;
            if (result.res.length != 0) {
              if (result.res[0]["CNPJ"] != undefined) {
                const stringCnpj = result.res[0]["CNPJ"]
                  .replace("/", "")
                  .replace(".", "")
                  .replace(".", "")
                  .replace("-", "");
                const newResult = formatString(
                  result.res,
                  Object.keys(result.res[0])
                );
                setCNPJ(stringCnpj);
                setFile(newResult);
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setRemoveLoading(true);
        });
    }
  }, []);

  const formatString = (data, keys) => {
    var resultString = [];

    for (const i in data) {
      const newData = keys
        .map((key) => {
          let value = data[i][key];
          if (key.toLocaleUpperCase().includes("DATA")) {
            // se for data
            const newFormatDate = dateRender(value);
            value = newFormatDate;
          }

          if (key.toLocaleUpperCase().includes("CNPJ")) {
            // se for cnpj

            if (value != undefined || value != null) {
              value = value.replace(
                /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                "$1.$2.$3/$4-$5"
              );
            } else {
              value = "";
            }
          }
          if (
            typeof value != "string" &&
            key.toLocaleUpperCase().includes("VALOR")
          ) {
            //se não for string

            value = moneyFrom(value);
          } else if (typeof value != "string") {
            if (value != undefined || value != null) {
              value = value.toString();
            } else {
              value = "";
            }
          }
          return { [key]: value };
        })
        .reduce((prev, next) => {
          return { ...prev, ...next };
        }, {});
      resultString.push(newData);
    }
    return resultString;
  };
  const dateRender = (dateReq) => {
    const nameMonth = {
      0: "01",
      1: "02",
      2: "03",
      3: "04",
      4: "05",
      5: "06",
      6: "07",
      7: "08",
      8: "09",
      9: "10",
      10: "11",
      11: "12",
    };
    const date = new Date(dateReq);
    if (dateReq == "") {
      return "";
    } else {
      date.setDate(date.getDate() + 1);

      var day = date.getDate();
      if (date.getDate() < 10) {
        var day = "0" + date.getDate();
      }
      const formatDate =
        day + "/" + nameMonth[date.getMonth()] + "/" + date.getFullYear();

      return formatDate;
    }
  };

  const moneyFrom = (money) => {
    if (money == "" || typeof money == String) {
    } else {
      var format = { minimumFractionDigits: 2, currency: "BRL" };
      return money.toLocaleString("pt-BR", format);
    }
  };

  return (
    <>
      {!removeLoading && <Loading />}
      {resultfile.length != 0 ? (
        <>
          <TableFiles
            files={resultfile}
            cnpj={cnpj}
            name={"Alterar-Detalhes"}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
}
